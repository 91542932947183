import { WebQuestion } from "../types/webQuestion";
import { TypeEnum } from "../types/answerTemplate";

export function isURL(str: string) {
  const pattern = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
  return pattern.test(str);
}

export const questionHasRedirectUrl = (question?: WebQuestion) => {
  return (
    (question?.answerTemplate?.type === TypeEnum.END_BLOCK || question?.answerTemplate === null) &&
    isURL(question?.redirectPath ?? "")
  );
};

export const redirectHandler = (question: WebQuestion) => {
  if (questionHasRedirectUrl(question)) {
    setTimeout(() => {
      window.location.replace(question.redirectPath ?? "");
    }, 4000);
  }
};
