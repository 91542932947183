import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { FrownOutlined, HeartFilled, MehOutlined, SmileOutlined, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import { NpsIconEnum } from "../../../types/npsInfoParams";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const smileIcons: Record<number, ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <MehOutlined />,
  5: <SmileOutlined />,
  6: <SmileOutlined />,
};

type Props = {
  readonly iconType?: NpsIconEnum;
  readonly maxValue: 5 | 6 | 10;
  readonly onChange: (value?: string) => void;
};

const Rating: FC<Props> = ({ iconType, maxValue, onChange }) => {
  const [val, setVal] = useState<number | undefined>();
  const qId = useSelector((state: RootState) => state.questions.question?.questionId);
  const [currentValue, setCurrentValue] = useState<number>();

  const isIcon = useCallback(
    (type: NpsIconEnum) => {
      return iconType === type;
    },
    [iconType],
  );

  const handleChange = useCallback(
    (value$: number) => {
      setCurrentValue(value$);
      setVal(value$);
      onChange(value$ ? String(value$) : undefined);
    },
    [setVal, onChange],
  );

  const getSmile = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{smileIcons[index + 1]}</span>;
    },
    [currentValue],
  );

  const getNumeric = useCallback(
    ({ index }: { index?: number }) => {
      if (index === undefined) return null;
      const isActive = index + 1 === currentValue;
      return <span style={{ color: isActive ? "#fadb14" : "#d9d9d9" }}>{index + 1}</span>;
    },
    [currentValue],
  );

  const getCharacter = useCallback(() => {
    if (isIcon(NpsIconEnum.NUMERIC)) {
      return getNumeric;
    } else if (isIcon(NpsIconEnum.SMILE)) {
      return getSmile;
    } else if (isIcon(NpsIconEnum.HEART)) {
      return <HeartFilled />;
    } else {
      return <StarFilled />;
    }
  }, [iconType, getSmile, getNumeric]);

  useEffect(() => {
    setVal(0);
    setCurrentValue(undefined);
  }, [qId]);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Rate
        value={val}
        allowHalf={false}
        onChange={handleChange}
        count={isIcon(NpsIconEnum.SMILE) ? maxValue : maxValue}
        character={getCharacter()}
        style={{
          fontSize: isIcon(NpsIconEnum.NUMERIC) || maxValue === 5 || maxValue === 6 ? 40 : 25,
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      />
    </div>
  );
};

export default Rating;
