import {FC} from "react";
import {ConfigProvider, Spin} from "antd";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {TypeEnum} from "../../types/answerTemplate";
import HeaderComponent from "../../components/header";
import CsiComponent from "./components/csi";
import SingleChoiceComponent from "./components/singleChoice";
import MultipleChoiceComponent from "./components/multipleChoice";
import CustomComponent from "./components/custom";
import CustomAnswerComponent from "./components/customAnswer";
import TitleComponent from "./components/title";
import NpsComponent from "./components/nps";
import FooterComponent from "../../components/footer";
import FileUploadComponent from "./components/fileUpload";
import ButtonsComponent from "./components/buttons";
import {WebQuestion} from "../../types/webQuestion";
import EndBlock from "./components/endBlock";
import MediaFile from "./components/mediaFile";
import {LoyaltyIndexComponent} from "./components/loyaltyIndex";

const SurveyPage: FC = () => {
  const { showLoader, appColor } = useSelector((state: RootState) => state.utils);

  return (
    <ConfigProvider theme={{ token: { colorPrimary: appColor } }}>
      <SurveyPageProviders appColor={appColor} loading={showLoader} />
    </ConfigProvider>
  );
};

type SurveyPageProvidersProps = {
  readonly appColor: string;
  readonly loading: boolean;
};

const SurveyPageProviders: FC<SurveyPageProvidersProps> = (props) => {
  const { appColor, loading } = props;

  if (!appColor) {
    return (
      <div style={{ height: "100vh", display: "grid", alignItems: "center", justifyContent: "center" }}>
        <Spin spinning />
      </div>
    );
  }

  return <Container appColor={appColor} loading={loading} />;
};

type ContainerProps = SurveyPageProvidersProps;

const Container: FC<ContainerProps> = (props) => {
  const { appColor, loading } = props;
  const question = useSelector((state: RootState) => state.questions.question);

  return (
    <div className={"survey-page"} style={{ "--app-color": appColor } as any}>
      <HeaderComponent />
      <div className={"content-container"}>
        {question?.mediaProperties && <MediaFile data={question.mediaProperties} />}
        <TitleComponent />
        <div className={"variables-container"} style={{ opacity: loading ? 0.5 : undefined }}>
          <Content question={question} />
          {question?.answerTemplate?.addCustomAnswer && <CustomAnswerComponent />}
        </div>
        {!question?.endBlock && question?.answerTemplate?.type !== TypeEnum.FILE_UPLOAD && (
          <ButtonsComponent loading={loading} />
        )}
      </div>
      <FooterComponent />
    </div>
  );
};

type ContentProps = { question?: WebQuestion };

const Content: FC<ContentProps> = (props) => {
  const { question } = props;

  switch (question?.answerTemplate?.type) {
    case TypeEnum.SINGLE_CHOICE:
      return <SingleChoiceComponent />;
    case TypeEnum.MULTIPLE_CHOICE:
      return <MultipleChoiceComponent />;
    case TypeEnum.IIN:
    case TypeEnum.EMAIL:
    case TypeEnum.NUMBER:
    case TypeEnum.TEXT:
    case TypeEnum.DATE:
    case TypeEnum.DATETIME:
      return <CustomComponent />;
    case TypeEnum.CSI:
      return <CsiComponent />;
    case TypeEnum.NPS:
      return <NpsComponent />;
    case TypeEnum.FILE_UPLOAD:
      return <FileUploadComponent />;
    case TypeEnum.LOYALTY_INDEX:
      return <LoyaltyIndexComponent />
  }

  if (question?.endBlock) {
    return <EndBlock />;
  }

  return <div />;
};

export default SurveyPage;
