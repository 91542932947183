import { FC, useEffect, useState } from "react";
import ButtonGroup from "antd/es/button/button-group";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";

const LoyaltyIndexComponent: FC = () => {
  const { question, answerTemplate } = useSelector((state: RootState) => {
    const question = state.questions?.question;
    return {
      question,
      answerTemplate: question?.answerTemplate,
    };
  });
  const { setUserAnswer } = useActions();
  const [activeButton, setActiveButton] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveButton(index);
    setUserAnswer({ answerValue: String(index), csiAnswers: [] });
  };

  useEffect(() => {
    setActiveButton(null);
  }, [question, answerTemplate]);

  return (
    <div>
      <ButtonGroup className="loyalty-button-group">
        {Array.from(Array(11).keys()).map((number) => (
          <Button
            key={number}
            className="loyalty-button"
            type={activeButton === number ? "primary" : "default"}
            onClick={() => handleClick(number)}
          >
            {number}
          </Button>
        ))}
      </ButtonGroup>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <span
          style={{
            fontFamily: question?.surveyDecor?.textFont,
            overflow: "hidden",
            width: "45%",
            overflowWrap: "break-word",
          }}
          className={question?.surveyDecor?.titleFont ? "disable-global-font" : ""}
        >
          {(answerTemplate?.lowestScoreLabel ?? "").slice(0, 30)}
        </span>
        <span
          style={{
            fontFamily: question?.surveyDecor?.textFont,
            overflow: "hidden",
            width: "45%",
            overflowWrap: "break-word",
            textAlign: "right",
          }}
          className={question?.surveyDecor?.titleFont ? "disable-global-font" : ""}
        >
          {(answerTemplate?.highestScoreLabel ?? "").slice(0, 30)}
        </span>
      </div>
    </div>
  );
};

export { LoyaltyIndexComponent };
