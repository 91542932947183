import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Languages } from "../../../types/surveyLanguage";
import { questionHasRedirectUrl } from "../../../helpers/redirectHandler";

const EndBlock: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);
  const currentLanguage = useSelector((state: RootState) => state.utils.lang);
  const [isShowingMessage, setIsShowingMessage] = useState(false);

  useEffect(() => {
    if (questionHasRedirectUrl(question) && question?.redirectPath) {
      setIsShowingMessage(true);
    }
  }, [question]);

  const fontObj = {
    className: question?.surveyDecor?.textFont ? "disable-global-font" : "",
    style: {
      fontFamily: question?.surveyDecor?.textFont,
      fontSize: 16,
    },
  };

  const linkProps = {
    ...fontObj,
    href: "https://surveysuite.io/?utm_source=svi_6lep5avc3w&utm_medium=cpa&utm_campaign=for-lead",
    style: { ...fontObj.style, fontWeight: 600 },
    rel: "noreferrer",
    target: "_blank",
  };

  const SSLink = () => <a {...linkProps}>surveysuite.io</a>;

  if (isShowingMessage && question?.redirectPath) {
    const redirectPath = question?.redirectPath;
    return (
      <div {...fontObj}>
        {currentLanguage?.langCode === Languages.EN && `You will be redirected to ${redirectPath} in a few seconds.`}
        {currentLanguage?.langCode === Languages.KK && `Сіз бірнеше секунд ішінде ${redirectPath} бағытталасыз.`}
        {currentLanguage?.langCode === Languages.FR &&
          `Vous serez redirigé vers ${redirectPath} dans quelques secondes.`}
        {currentLanguage?.langCode === Languages.TR &&
          `Birkaç saniye içinde ${redirectPath} adresine yönlendirileceksiniz.`}
        {currentLanguage?.langCode === Languages.UZ &&
          `Siz bir necha soniya ichida ${redirectPath} manziliga yo'naltirilasiz.`}
        {(!currentLanguage ||
          (currentLanguage?.langCode !== Languages.EN &&
            currentLanguage?.langCode !== Languages.KK &&
            currentLanguage?.langCode !== Languages.FR &&
            currentLanguage?.langCode !== Languages.TR &&
            currentLanguage?.langCode !== Languages.UZ)) &&
          `Вы будете перенаправлены на ${redirectPath} через несколько секунд.`}
      </div>
    );
  }

  if (currentLanguage?.langCode === Languages.EN) {
    return (
      <div {...fontObj}>
        Survey completed. Visit <SSLink /> for more information about Survey Suite service.
      </div>
    );
  } else if (currentLanguage?.langCode === Languages.KK) {
    return (
      <div {...fontObj}>
        Сауалнама аяқталды. Survey Suite қызметі туралы қосымша ақпарат алу үшін <SSLink /> веб-сайтқа кіріңіз.
      </div>
    );
  }
  return (
    <div {...fontObj}>
      Опрос завершен. Узнайте больше о сервисе Survey Suite на сайте <SSLink />.
    </div>
  );
};

export default EndBlock;
