import {GET, POST, PUT} from "../types/httpMethod";
import {requestApi} from "./requestApi";
import {WebQuestion} from "../types/webQuestion";
import {setAnswer, setCustomAnswer, setQuestion} from "../store/reducers/questions";
import {Answer} from "../types/answer";
import {setAppColor, setAppLogoUrl, setLanguage} from "./utilsService";
import {answerInitial} from "../helpers/answerInitial";
import getHttpsUrl from "../helpers/getHttpsUrl";
import {manageMetaData} from "../helpers/metaDataManager";
import {AnswerQuestion} from "../types/answerQuestion";
import {RcFile} from "antd/es/upload";
import {manageSurveyLanguages} from "../helpers/manageSurveyLanguages";
import {setLocale} from "react-redux-i18n";
import store from "../store";
import {getClientLanguageOrDefault} from "../helpers/clientLanguage";
import {changeLanguage} from "./requests";
import {redirectHandler} from "../helpers/redirectHandler";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getNextQuestion = createAsyncThunk<
  void,
  {
    readonly instanceId: string;
    readonly forceUpdateLanguage?: boolean;
  }
>("questions/getNextQuestion", async ({ instanceId, forceUpdateLanguage }, { dispatch }) => {
  try {
    const res: WebQuestion = await requestApi(GET, `/survey/${instanceId}`, {});
    redirectHandler(res);

    const customAnswerIsRequired = res?.answerTemplate?.customAnswerIsRequired;

    let choicesValue;
    if (!customAnswerIsRequired) {
      choicesValue = [];
    } else {
      choicesValue = undefined;
    }

    dispatch(setQuestion(res));
    dispatch(setUserAnswer({ answerValue: undefined, csiAnswers: [], choices: choicesValue }));

    const storedLanguage = store.getState().utils.lang?.langCode;
    const newLanguage = getClientLanguageOrDefault(res?.languages?.map((it) => it.langCode));

    if (!res?.survLang) {
      await changeLanguage(instanceId, newLanguage);
    } else if (!storedLanguage || storedLanguage !== newLanguage || forceUpdateLanguage) {
      await dispatch(setLanguage(res.survLang));
    }

    if (res.logoPath) {
      await dispatch(setAppLogoUrl(getHttpsUrl(res.logoPath)));
    }
    if (res?.themeColor) {
      await dispatch(setAppColor(res.themeColor));
    }

    manageMetaData(res);
    manageSurveyLanguages(res?.languages);
  } catch (e) {
    throw e;
  }
});

export const answerQuestion = createAsyncThunk<void, AnswerQuestion>(
  "questions/answerQuestion",
  async ({ instanceId, questionId, answer }, { dispatch }) => {
    try {
      await requestApi(POST, `/survey/${instanceId}/${questionId}`, answer || answerInitial);
      dispatch(setUserAnswer({ answerValue: undefined, csiAnswers: [] }));
      dispatch(setUserCustomAnswer(undefined));
      dispatch(getNextQuestion({ instanceId }));
    } catch (e) {
      throw e;
    }
  },
);

export const uploadFile = createAsyncThunk<void, Omit<AnswerQuestion, "answer"> & { readonly files: Array<RcFile> }>(
  "questions/uploadFile",
  async ({ instanceId, questionId, files }) => {
    try {
      await requestApi(POST, `/survey/${instanceId}/${questionId}/upload`, files, false, true);
    } catch (e) {
      throw e;
    }
  },
);

export const changeLang = createAsyncThunk<void, { instanceId: string; langCode: string }>(
  "questions/changeLang",
  async ({ instanceId, langCode }, { dispatch }) => {
    try {
      await requestApi(PUT, `/survey/${instanceId}/${langCode}`, {});
      dispatch(getNextQuestion({ instanceId, forceUpdateLanguage: true }));
      dispatch(setLocale(langCode));
    } catch (e) {
      throw e;
    }
  },
);

export const setUserAnswer = createAsyncThunk<void, Answer>("questions/setUserAnswer", (payload, { dispatch }) => {
  dispatch(setAnswer(payload));
});

export const setUserCustomAnswer = createAsyncThunk<void, string | undefined>(
  "questions/setUserCustomAnswer",
  (payload, { dispatch }) => {
    dispatch(setCustomAnswer(payload));
  },
);
